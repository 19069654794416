import * as React from "react";
import { CustomerNotificationsWithDialogWrapper } from "components/shared/CustomerNotifications/CustomerNotificationsWithDialogWrapper";
import {
  CovidBannerVersions,
  ReasonsToBelieveWrapper,
} from "components/shared/ReasonsToBelieve/ReasonsToBelieveWrapper";
import { CustomerNotificationsInPageProps } from "components/shared/StorefrontWizard/TravelAdvisory/typings";
import { UitkSpacing } from "@egds/react-core/spacing";

/**
 * Manage the different versions for customer notifications
 */
export const CustomerNotificationsInPage: React.FC<CustomerNotificationsInPageProps> = (
  props: CustomerNotificationsInPageProps
) => {
  const { reasonsToBelieveVersion = CovidBannerVersions.FOUR } = props;
  return (
    <>
      {reasonsToBelieveVersion === CovidBannerVersions.ONE && (
        <UitkSpacing padding={{ inline: "six" }}>
          <CustomerNotificationsWithDialogWrapper
            location="IN_PAGE"
            className="customerNotificationSquare customerNotificationWithDialog"
          />
        </UitkSpacing>
      )}
      {reasonsToBelieveVersion === CovidBannerVersions.TWO && (
        <ReasonsToBelieveWrapper location="IN_PAGE" className="reasons-to-believe" version={CovidBannerVersions.TWO} />
      )}
      {reasonsToBelieveVersion === CovidBannerVersions.THREE && (
        <ReasonsToBelieveWrapper
          location="IN_PAGE"
          className="reasons-to-believe"
          version={CovidBannerVersions.THREE}
        />
      )}
      {reasonsToBelieveVersion === CovidBannerVersions.FOUR && (
        <ReasonsToBelieveWrapper location="IN_PAGE" className="reasons-to-believe" version={CovidBannerVersions.FOUR} />
      )}
    </>
  );
};
